<template>
  <footer class="lFooter cFooter">
    <div class="cFooter__wrapper">
      <div class="cFooter__background">
        <picture>
          <source
            type="image/webp"
            media="(min-width: 768px)"
            srcset="
              @/assets/image/webp/footer-background-pc.webp    1x,
              @/assets/image/webp/footer-background-pc@2x.webp 2x
            "
          />
          <source
            media="(min-width: 768px)"
            srcset="@/assets/image/footer-background-pc.jpg 1x, @/assets/image/footer-background-pc@2x.jpg 2x"
          />
          <source
            type="image/webp"
            srcset="@/assets/image/webp/footer-background.webp 1x, @/assets/image/webp/footer-background@2x.webp 2x"
          />
          <img
            src="@/assets/image/footer-background.jpg"
            srcset="@/assets/image/footer-background@2x.jpg 2x"
            decoding="async"
            width="375"
            height="550"
            alt=""
          />
        </picture>
      </div>

      <div class="cFooter__content">
        <div class="cFooter__contentTop">
          <ul class="cFooter__sns">
            <li>
              <a href="https://www.instagram.com/_polepole_/" target="_blank" rel="noopener noreferrer">
                <img src="@/assets/image/instagram-logo.svg" alt="polepole公式インスタグラムに移動する" />
              </a>
            </li>

            <li>
              <a href="https://www.facebook.com/polepole.ay/" target="_blank" rel="noopener noreferrer">
                <img src="@/assets/image/facebook-logo.svg" alt="polepole公式フェイスブックに移動する" />
              </a>
            </li>

            <li>
              <a href="https://line.me/R/ti/p/%40pqt8866z" target="_blank" rel="noopener noreferrer">
                <img src="@/assets/image/line-logo.svg" alt="polepole公式ラインに移動する" />
              </a>
            </li>
          </ul>

          <div class="cFooter__info">
            <p class="cFooter__infoText">Open 10:00-17:00</p>

            <p class="cFooter__infoText">Closed day sun. and mon.</p>

            <p class="cFooter__infoText">phone 080-2429-0957</p>
          </div>
        </div>

        <div class="cFooter__contentBottom">
          <a href="/">
            <img src="@/assets/image/footer-logo.svg" alt="トップページに戻る" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
.cFooter {
  width: 100%;
  height: g.px-vw(550, 375);

  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding-top: 80px;
  }

  &__contentTop {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__sns {
    display: flex;
    align-items: center;
    justify-content: center;

    & li {
      width: 25px;
      height: 25px;

      & + li {
        margin-left: 14px;
      }
    }
  }

  &__info {
    margin-top: 2.4rem;
  }

  &__infoText {
    font-family: var(--lato);
    font-size: 1.2rem;
    letter-spacing: 0.04em;
    line-height: 1.9;
    color: #fff;
    text-align: center;
  }

  &__contentBottom {
    position: absolute;
    bottom: 120px;
    left: calc(50% - 60px);
    width: 120px;
    height: 58px;
  }
}

@include g.responsive(sm) {
  .cFooter {
    position: relative;
    z-index: 10;
    height: 100vh;

    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }

    &__contentTop {
      margin-bottom: 20px;
    }

    &__contentBottom {
      bottom: 50px;
    }
  }
}
</style>
