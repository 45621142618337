import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/menu",
    name: "Menu",
    component: () => import(/* webpackChunkName: "Menu" */ "../views/Menu.vue"),
  },
  {
    path: "/salon",
    name: "Salon",
    component: () => import(/* webpackChunkName: "about" */ "../views/Salon.vue"),
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      if (savedPosition) {
        setTimeout(() => {
          return savedPosition;
        }, 590);
      } else {
        setTimeout(() => {
          resolve({ left: 0, top: 0 });
        }, 590);
      }
    });
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
