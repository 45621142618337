import { gsap } from "gsap";

export default function loading() {
  document.body.classList.add("is-home");

  const jsLoad = document.querySelector(".js-load");
  const jsHome = document.querySelector(".js-home");

  if (!jsLoad || !jsHome) return;

  document.body.classList.add("is-load");

  const jsLoadText = jsLoad.querySelector(".js-load-text");
  const charList = jsLoadText.querySelectorAll(".char");

  // 初期設定
  const styles = {
    position: "fixed",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
  };

  Object.keys(styles).forEach((key) => {
    document.getElementById("app").style[key] = styles[key];
  });

  gsap.set(".js-load-fadeIn", {
    opacity: 0,
    scale: 1.05,
    willChange: "opacity, transform",
    backfaceVisibility: "hidden",
  });

  document.querySelectorAll(".js-load-fadeIn02").forEach((el) => {
    el.classList.add("is-hide");
  });

  gsap.set(".cHeader", {
    opacity: 0,
    willChange: "opacity",
    backfaceVisibility: "hidden",
  });

  // アニメーション
  charList.forEach((char) => {
    gsap.set(char, {
      opacity: 0,
      scale: 1.01,
      willChange: "opacity, transform",
      backfaceVisibility: "hidden",
    });

    gsap.to(char, {
      duration: 1,
      opacity: 1,
      scale: 1,
      ease: "power1.out",
      delay: "random(0.4, 2)",
      onComplete: () => {
        char.style.willChange = "";
      },
    });
  });

  const tl = gsap.timeline({});

  tl.to(
    jsLoad,
    {
      duration: 1.6,
      opacity: 0,
      ease: "power4.inOut",
      onComplete: () => {
        jsLoad.remove();
      },
    },
    3
  )
    .to(".js-load-fadeIn", {
      duration: 1.6,
      opacity: 1,
      scale: 1,
      ease: "power4.inOut",
      onComplete: () => {
        document.querySelectorAll(".js-load-fadeIn").forEach((el) => {
          el.style.willChange = "";
        });
      },
    })
    .to(
      ".js-load-fadeIn02",
      {
        onComplete: () => {
          document.querySelectorAll(".js-load-fadeIn02").forEach((el) => {
            el.classList.remove("is-hide");
          });
        },
      },
      "<"
    )
    .to(".cHeader", {
      duration: 1,
      opacity: 1,
      ease: "power4.inOut",
      onComplete: () => {
        document.querySelector(".cHeader").style.willChange = "";
        // ロック解除
        Object.keys(styles).forEach((key) => {
          document.getElementById("app").style[key] = "";
        });

        document.body.classList.remove("is-load");
      },
    });
}
