<template>
  <div class="lContainer js-home">
    <main class="lMain">
      <div class="lFV">
        <div class="lFV__wrapper">
          <div class="lFV__logo js-load-fadeIn">
            <img src="@/assets/image/fv-logo.svg" alt="ロゴ画像" />
          </div>
        </div>
      </div>

      <div class="lFixed">
        <div class="lFixed__wrapper">
          <div class="lFixed__block lFixed__block--image lFixed__block--image01">
            <div class="lFixed__scroller">
              <div class="lFixed__image js-load-fadeIn02">
                <picture>
                  <source
                    type="image/webp"
                    srcset="@/assets/image/webp/top-slide01.webp 1x, @/assets/image/webp/top-slide01@2x.webp 2x"
                  />
                  <img
                    src="@/assets/image/top-slide01.jpg"
                    srcset="@/assets/image/top-slide01@2x.jpg 2x"
                    decoding="async"
                    width="375"
                    height="768"
                    alt=""
                  />
                </picture>
              </div>
            </div>
          </div>

          <div class="lFixed__block lFixed__block--text">
            <div class="lFixed__scroller">
              <section class="lSection lSection--first">
                <div class="lSection__wrapper">
                  <h2 class="lSection__h2 js-scroll-target">
                    Relax time of
                    <br />
                    hand polepole.
                  </h2>

                  <p class="lSection__text js-scroll-target">
                    polepoleがいつも想うこと。
                    <br />
                    ご来店いただいたあなたに
                    <br />
                    日々の忙しさを忘れて
                    <br />
                    ゆっくり、のんびりしていただくこと。
                    <br />
                    決して派手なことはできないけれど
                    <br />
                    この手のぬくもりで、ココロまでもほぐす
                    <br />
                    上質な癒しをご提供します。
                  </p>

                  <div class="lSection__image js-scroll-target">
                    <picture>
                      <source
                        type="image/webp"
                        media="(min-width: 768px)"
                        srcset="@/assets/image/webp/top-hand01-pc.webp 1x, @/assets/image/webp/top-hand01-pc@2x.webp 2x"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="@/assets/image/top-hand01-pc.png 1x, @/assets/image/top-hand01-pc@2x.png 2x"
                      />
                      <source
                        type="image/webp"
                        srcset="@/assets/image/webp/top-hand01.webp 1x, @/assets/image/webp/top-hand01@2x.webp 2x"
                      />
                      <img
                        src="@/assets/image/top-hand01.png"
                        srcset="@/assets/image/top-hand01@2x.png 2x"
                        decoding="async"
                        width="291"
                        height="208"
                        alt="手のイラスト その1"
                      />
                    </picture>
                  </div>
                </div>
              </section>
            </div>

            <div class="lFixed__handImage js-scroll-target">
              <picture>
                <source
                  type="image/webp"
                  media="(min-width: 768px)"
                  srcset="@/assets/image/webp/top-hand01-pc.webp 1x, @/assets/image/webp/top-hand01-pc@2x.webp 2x"
                />
                <source
                  media="(min-width: 768px)"
                  srcset="@/assets/image/top-hand01-pc.png 1x, @/assets/image/top-hand01-pc@2x.png 2x"
                />
                <source
                  type="image/webp"
                  srcset="@/assets/image/webp/top-hand01.webp 1x, @/assets/image/webp/top-hand01@2x.webp 2x"
                />
                <img
                  src="@/assets/image/top-hand01.png"
                  srcset="@/assets/image/top-hand01@2x.png 2x"
                  decoding="async"
                  width="291"
                  height="208"
                  alt="手のイラスト その1"
                />
              </picture>
            </div>
          </div>

          <div class="lFixed__block lFixed__block--pcImage">
            <div class="lFixed__scroller">
              <div class="lFixed__image">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                      @/assets/image/webp/top-full-image-pc.webp    1x,
                      @/assets/image/webp/top-full-image-pc@2x.webp 2x
                    "
                  />
                  <img
                    src="@/assets/image/top-full-image-pc.jpg"
                    srcset="@/assets/image/top-full-image-pc@2x.jpg 2x"
                    decoding="async"
                    width="1440"
                    height="768"
                    alt=""
                  />
                </picture>
              </div>
            </div>
          </div>

          <div class="lFixed__block lFixed__block--image">
            <div class="lFixed__scroller">
              <div class="lFixed__image">
                <picture>
                  <source
                    type="image/webp"
                    srcset="@/assets/image/webp/top-slide02.webp 1x, @/assets/image/webp/top-slide02@2x.webp 2x"
                  />
                  <img
                    src="@/assets/image/top-slide02.jpg"
                    srcset="@/assets/image/top-slide02@2x.jpg 2x"
                    decoding="async"
                    width="375"
                    height="768"
                    alt=""
                  />
                </picture>
              </div>
            </div>
          </div>

          <div class="lFixed__block lFixed__block--text">
            <div class="lFixed__scroller">
              <section class="lSection lSection--second">
                <div class="lSection__wrapper">
                  <div class="lSection__flex">
                    <div class="lSection__block">
                      <h2 class="lSection__h2 js-scroll-target">menu</h2>

                      <p class="lSection__text js-scroll-target">
                        oil treatment
                        <br />
                        body care
                        <br />
                        reflexology
                        <br />
                        head care
                        <br />
                        facial treatment
                        <br />
                        hand treatment
                        <br />
                        horny care
                      </p>
                    </div>

                    <div class="lSection__vertical js-scroll-target">
                      <p>
                        丁寧に、大切に。
                        <br />
                        手で触れるということ
                        <br />
                        心を込めて癒します。
                      </p>
                    </div>
                  </div>

                  <div class="lSection__link js-scroll-target">
                    <router-link to="/menu">
                      menu and price
                      <span class="icon"></span>
                    </router-link>
                  </div>

                  <div class="lSection__image js-scroll-target">
                    <picture>
                      <source
                        type="image/webp"
                        media="(min-width: 768px)"
                        srcset="@/assets/image/webp/top-hand02-pc.webp 1x, @/assets/image/webp/top-hand02-pc@2x.webp 2x"
                      />
                      <source
                        media="(min-width: 768px)"
                        srcset="@/assets/image/top-hand02-pc.png 1x, @/assets/image/top-hand02-pc@2x.png 2x"
                      />
                      <source
                        type="image/webp"
                        srcset="@/assets/image/webp/top-hand02.webp 1x, @/assets/image/webp/top-hand02@2x.webp 2x"
                      />
                      <img
                        src="@/assets/image/top-hand02.png"
                        srcset="@/assets/image/top-hand02@2x.png 2x"
                        decoding="async"
                        width="375"
                        height="239"
                        alt="手のイラスト その2"
                      />
                    </picture>
                  </div>
                </div>
              </section>

              <BaseAccess class="lSection--third" />
            </div>
          </div>
        </div>
      </div>
    </main>

    <div class="lSlider">
      <div class="lSlider__wrapper js-load-fadeIn02">
        <swiper
          class="lSlider__swiper"
          @swiper="setSwiper"
          :speed="1600"
          :effect="'fade'"
          :fadeEffect="{
            crossFade: true,
          }"
          :loop="true"
          :centeredSlides="true"
          :autoplay="{
            delay: 4000,
            disableOnInteraction: false,
          }"
        >
          <swiper-slide class="lSlider__item">
            <div class="lSlider__itemWrapper">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                    @/assets/image/webp/top-slide-image01-pc.webp    1x,
                    @/assets/image/webp/top-slide-image01-pc@2x.webp 2x
                  "
                />
                <img
                  src="@/assets/image/top-slide-image01-pc.jpg"
                  srcset="@/assets/image/top-slide-image01-pc@2x.jpg 2x"
                  decoding="async"
                  width="728"
                  height="768"
                  alt=""
                />
              </picture>
            </div>
          </swiper-slide>

          <swiper-slide class="lSlider__item">
            <div class="lSlider__itemWrapper">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                    @/assets/image/webp/top-slide-image02-pc.webp    1x,
                    @/assets/image/webp/top-slide-image02-pc@2x.webp 2x
                  "
                />
                <img
                  src="@/assets/image/top-slide-image02-pc.jpg"
                  srcset="@/assets/image/top-slide-image02-pc@2x.jpg 2x"
                  decoding="async"
                  width="728"
                  height="768"
                  alt=""
                />
              </picture>
            </div>
          </swiper-slide>

          <swiper-slide class="lSlider__item">
            <div class="lSlider__itemWrapper">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                    @/assets/image/webp/top-slide-image03-pc.webp    1x,
                    @/assets/image/webp/top-slide-image03-pc@2x.webp 2x
                  "
                />
                <img
                  src="@/assets/image/top-slide-image03-pc.jpg"
                  srcset="@/assets/image/top-slide-image03-pc@2x.jpg 2x"
                  decoding="async"
                  width="728"
                  height="768"
                  alt=""
                />
              </picture>
            </div>
          </swiper-slide>

          <swiper-slide class="lSlider__item">
            <div class="lSlider__itemWrapper">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                    @/assets/image/webp/top-slide-image04-pc.webp    1x,
                    @/assets/image/webp/top-slide-image04-pc@2x.webp 2x
                  "
                />
                <img
                  src="@/assets/image/top-slide-image04-pc.jpg"
                  srcset="@/assets/image/top-slide-image04-pc@2x.jpg 2x"
                  decoding="async"
                  width="728"
                  height="768"
                  alt=""
                />
              </picture>
            </div>
          </swiper-slide>

          <swiper-slide class="lSlider__item">
            <div class="lSlider__itemWrapper">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                    @/assets/image/webp/top-slide-image05-pc.webp    1x,
                    @/assets/image/webp/top-slide-image05-pc@2x.webp 2x
                  "
                />
                <img
                  src="@/assets/image/top-slide-image05-pc.jpg"
                  srcset="@/assets/image/top-slide-image05-pc@2x.jpg 2x"
                  decoding="async"
                  width="728"
                  height="768"
                  alt=""
                />
              </picture>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import BaseAccess from "@/components/BaseAccess.vue";
import loading from "@/libs/loading";
import Foundation from "@/libs/foundation";
import SplitText from "@/libs/split-text";

import "swiper/swiper-bundle.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import SwiperCore, { Autoplay, EffectFade } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/vue";

SwiperCore.use([Autoplay, EffectFade]);

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Home",
  components: {
    Footer,
    BaseAccess,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiper: null,
    };
  },
  methods: {
    setSwiper(el) {
      this.swiper = el;
    },
    scrollAnime() {
      this.targetArry = gsap.utils.toArray(".js-scroll-target").map((target) => {
        return gsap.from(target, {
          scrollTrigger: {
            trigger: target,
            start: "top 90%",
          },
          opacity: 0,
          scale: 0.95,
          y: 30,
          duration: 2,
          ease: "power4.out",
        });
      });
    },
  },
  mounted() {
    new SplitText(".js-split-text");

    loading();

    Foundation();
    this.swiper.autoplay.start();

    this.scrollAnime();
    ScrollTrigger.refresh();
  },
  beforeUnmount() {
    this.targetArry.forEach((target) => {
      target.scrollTrigger.kill();
      target.kill();
    });
  },
};
</script>

<style lang="scss" scoped>
.js-load-fadeIn02 {
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: translateX(-101%);
    pointer-events: none;
    transition: transform 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }

  &.is-hide {
    &::after {
      transform: translateX(0);
    }
  }
}

.lFV {
  margin: 7.4rem 0 9.5rem;

  &__wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #fff;
  }

  &__logo {
    width: 170px;
    height: auto;
  }
}

.lSection {
  &--first {
    padding: 7.2rem 42px 20.8rem;

    & .lSection {
      &__h2 {
        font-family: var(--lato);
        font-size: 3rem;
        font-weight: 400;
        line-height: 1.6;
        letter-spacing: 0.05em;
      }

      &__text {
        font-size: 1.4rem;
        letter-spacing: 0.1em;
        line-height: 2.4;
        margin-top: 4rem;
      }

      &__image {
        display: none;
      }
    }
  }

  &--second {
    padding-top: 7rem;

    & .lSection {
      &__h2 {
        font-family: var(--lato);
        font-size: 2.6rem;
        font-weight: 400;
        line-height: 1.6;
        letter-spacing: 0.1em;
      }

      &__text {
        font-family: var(--lato);
        font-size: 1.2rem;
        letter-spacing: 0.08em;
        line-height: 1.6;
        margin-top: 2rem;
      }

      &__flex {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        max-width: 375px;
        margin: 0 auto;
        padding: 0 43px;
      }

      &__link {
        max-width: 375px;
        font-family: var(--lato);
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: 0.05em;
        margin: 3.7rem auto 0;
        padding: 0 43px;

        & > a {
          position: relative;
          padding: 0 0 1rem;

          & .icon {
            position: absolute;
            top: calc(50% - 0.7rem);
            right: 0;
            width: 0.7rem;
            height: 0.7rem;
            border-radius: 50%;
            background-color: #000;
          }

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: #000;
          }
        }
      }

      &__vertical {
        writing-mode: vertical-rl;
        font-size: 1.4rem;
        letter-spacing: 0.4em;
        line-height: 2.4;
      }

      &__image {
        width: 100%;
        max-width: 375px;
        margin: 6rem auto 0;
      }
    }
  }

  &--third {
    margin-top: 6rem;
    padding: 0 42px 7rem;
  }
}

.lSlider {
  display: none;
}

.lFixed {
  &__wrapper {
    width: 100%;
  }

  &__block {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;

    &--text {
      top: -100vh;
      background-color: #fff;

      & .lFixed__scroller {
        height: auto;
      }
    }

    &--image {
      z-index: -1;
    }

    &--pcImage {
      display: none;
    }
  }

  &__scroller {
    position: relative;
    width: 100%;
    height: 100vh;
  }

  &__image {
    position: relative;
    width: 100%;
    height: 100%;

    & img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__handImage {
    position: absolute;
    bottom: -36px;
    left: calc(50% - 145px);
    width: 291px;
    height: 208px;
    z-index: 1;
  }
}

@include g.responsive(sm) {
  .lFV {
    width: 100%;
    height: 100vh;
    margin: 0;

    &__wrapper {
      width: 100%;
      height: 100%;
      align-items: center;
    }
  }

  .lMain {
    width: 50%;
  }

  .lSlider {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50%;
    height: calc(100% - 100vh);
    background-color: #fff;

    &__wrapper {
      position: sticky;
      top: 0;
      width: 100%;
      height: 100vh;
    }

    &__swiper {
      height: 100%;
    }

    &__item {
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.swiper-slide-active,
      &.swiper-slide-duplicate-active,
      &.swiper-slide-prev {
        & img {
          animation: zoomUp 10s linear 0s 1 normal both;
        }
      }
    }

    &__itemWrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }

  .lSection {
    &--first {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      align-items: center;
      height: 100vh;
      padding: 0;

      & .lSection {
        &__wrapper {
          margin: 0 0 3rem 0.5rem;
        }

        &__h2 {
          font-size: 3rem;
        }

        &__text {
          margin-top: 3rem;
        }

        &__image {
          position: absolute;
          z-index: 10;
          left: g.px-vw(298, 1440);
          display: block;
          margin-top: 4.4rem;
        }
      }
    }

    &--second {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100vh;
      padding: 0;

      & .lSection {
        &__wrapper {
          width: 100%;
          max-width: 296px;
          margin: 0 0 2.5rem 1rem;
        }

        &__flex {
          width: 100%;
          max-width: initial;
          margin: 0;
          padding: 0;
        }

        &__block {
          margin-top: 4.4rem;
        }

        &__h2 {
          font-size: 2.4rem;
        }

        &__text {
          font-size: 1rem;
        }

        &__link {
          max-width: 200px;
          margin: 7rem 0 0;
          padding: 0;
        }

        &__image {
          position: absolute;
          left: g.px-vw(266, 1440);
          max-width: 719px;
          margin-top: 9.7rem;

          & img {
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }

    &--third {
      width: 100%;
      margin-top: 0;
      padding: 20.8rem 0 20rem;
    }
  }

  .lFixed {
    &__block {
      position: relative;
      width: 100%;
      top: 0;

      &--image {
        display: none;
      }

      &--pcImage {
        position: relative;
        z-index: 1;
        display: block;
        width: 100vw;
        min-width: 1366px;
      }
    }

    &__handImage {
      display: none;
    }
  }
}

@keyframes zoomUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
</style>
