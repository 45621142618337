export default class SplitText {
  constructor(el) {
    this.DOM = {};
    this.DOM.el = document.querySelectorAll(el);
    if (!this.DOM.el) {
      return;
    }
    this.DOM.el.forEach((element) => {
      this.chars = element.innerHTML.trim().split("");
      element.innerHTML = this._splitText();
    });
  }

  _splitText() {
    return this.chars.reduce((acc, curr) => {
      curr = curr.replace("＆", "&amp;");
      curr = curr.replace(/\s+/, "&nbsp;");
      return `${acc}<span class="char">${curr}</span>`;
    }, "");
  }
}
