<template>
  <Header />

  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>

  <div class="lLoad js-load" :class="{ 'is-show': $route.path === '/' }">
    <div class="lLoad__wrapper">
      <div class="lLoad__text js-load-text">
        <span class="js-split-text"> 丁寧に、大切に。 </span>

        <span class="js-split-text"> 手で触れるということ </span>

        <span class="js-split-text"> 心を込めて癒します。 </span>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  name: "App",
  components: {
    Header,
  },
};
</script>

<style lang="scss">
@use "./assets/scss/_base.scss";

.fade-enter-active,
.fade-leave-active {
  position: relative;
  transition: opacity 0.6s cubic-bezier(0.47, 0, 0.745, 0.715), filter 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  filter: blur(5px);
}

.lContainer {
  position: relative;
}

#app {
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss">
.lLoad {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: #fff;
  z-index: 9999;
  visibility: hidden;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__text {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    width: 100%;

    & span {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 3;
      letter-spacing: 0.5em;
      writing-mode: vertical-rl;
    }
  }
}

.is-home {
  & .lLoad.is-show {
    width: 100%;
    height: 100%;
    visibility: visible;
  }
}

@include g.responsive(sm) {
  .lLoad {
    &__text {
      & span {
        font-size: 2rem;
      }
    }
  }
}
</style>
