<template>
  <section class="cAccess">
    <h2 class="cAccess__h2 js-scroll-target">ACCESS</h2>

    <div class="cAccess__content">
      <div class="cAccess__address js-scroll-target">
        〒649-2211
        <br />
        和歌山県西牟婁郡白浜町1111-34
        <br />
        御幸ビルディング2F
      </div>

      <div class="cAccess__time js-scroll-target">
        Open 10:00-17:00
        <br />
        Closed day sun. and mon.
        <br />
        phone <a href="tel:+81-80-2429-0957">080-2429-0957</a>
        <br />
        LINE ID <a href="https://line.me/R/ti/p/@pqt8866z" target="_blank" rel="noopener noreferrer">@pqt8866z</a>

        <div class="cAccess__qrcode">
          <picture>
            <source
              type="image/webp"
              srcset="@/assets/image/webp/access-qrcode.webp 1x, @/assets/image/webp/access-qrcode@2x.webp 2x"
            />
            <img
              src="@/assets/image/access-qrcode.png"
              srcset="@/assets/image/access-qrcode@2x.png 2x"
              decoding="async"
              width="70"
              height="70"
              alt="公式ラインのQRコード"
            />
          </picture>
        </div>
      </div>

      <p class="cAccess__text js-scroll-target">
        お名前／ご予約を希望される日時／
        <br class="sp" />
        ご希望のメニューをLINEしてください。
        <br />
        polepoleからの返信でご予約完了となります。
        <br />
        ※当日のご予約はお電話でお問い合わせください。
      </p>

      <div class="cAccess__list js-scroll-target">
        <div class="cAccess__link">
          <router-link to="/salon">
            salon
            <span class="icon"></span>
          </router-link>
        </div>

        <div class="cAccess__link">
          <a href="https://goo.gl/maps/W15VconaDQi9SRqh9" target="_blank" rel="noopener noreferrer">
            googlemaps
            <span class="icon"></span>
          </a>
        </div>
      </div>

      <div class="cAccess__image js-scroll-target">
        <picture>
          <source media="(min-width: 768px)" srcset="@/assets/image/access-map-pc.svg" />
          <img src="@/assets/image/access-map.svg" decoding="async" width="335" height="215" alt="アクセスマップ画像" />
        </picture>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.cAccess {
  &__h2 {
    font-family: var(--lato);
    font-size: 2.6rem;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0.1em;
  }

  &__address {
    font-size: 1.2rem;
    letter-spacing: 0.05em;
    line-height: 2;
    margin-top: 2rem;
  }

  &__time {
    font-family: var(--lato);
    font-size: 1.4rem;
    letter-spacing: 0.08em;
    line-height: 1.8;
    margin-top: 2.7rem;

    & a {
      display: inline-block;
      text-decoration: underline #000;
      text-decoration-thickness: 1px;
      text-underline-offset: 0.15em;
    }
  }

  &__qrcode {
    display: none;
  }

  &__text {
    font-size: 1.2rem;
    letter-spacing: 0.05em;
    line-height: 2;
    margin-top: 4.8rem;
  }

  &__list {
    margin-top: 5.2rem;
  }

  &__link {
    max-width: 375px;
    font-family: var(--lato);
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.05em;
    margin: 0 auto;

    & > a {
      position: relative;
      padding: 0 0 1rem;

      & .icon {
        position: absolute;
        top: calc(50% - 0.7rem);
        right: 0;
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        background-color: #000;
      }

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #000;
      }

      &[target="_blank"] {
        & .icon {
          position: absolute;
          top: calc(50% - 0.7rem);
          right: 0;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background-color: #000;

          &::after {
            content: "";
            position: absolute;
            top: 0.2rem;
            left: -0.2rem;
            width: 100%;
            height: 100%;
            border: 1px solid #000;
            border-radius: 50%;
            background-color: #fff;
          }
        }
      }
    }

    & + .cAccess__link {
      margin-top: 3rem;
    }
  }

  &__image {
    width: 100%;
    max-width: 530px;
    margin: 6rem auto 0;

    & img {
      width: 100%;
      object-fit: cover;
    }
  }
}

@include g.responsive(sm) {
  .cAccess {
    max-width: 530px;
    margin: 0 auto;

    &__h2 {
      font-size: 2.4rem;
      max-width: 303px;
      margin: 0 auto;
    }

    &__content {
      & > :not(.cAccess__image, .cAccess__list) {
        max-width: 303px;
        margin: 3rem auto 0;
      }
    }

    &__time {
      position: relative;
      font-size: 1.2rem;

      & a {
        pointer-events: none;
      }
    }

    &__qrcode {
      position: absolute;
      top: calc(50% - 35px);
      right: 44px;
      display: block;
      width: 70px;
      height: 70px;

      & img {
        width: 100%;
        object-fit: cover;
      }
    }

    &__list {
      max-width: 303px;
      margin: 8rem auto 0;
    }

    &__link {
      max-width: 200px;
      margin: 0;
    }

    &__image {
      margin-top: 8rem;
    }
  }
}
</style>
