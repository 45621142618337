function foundation() {
  // brタグの読み上げ防止
  const elements = document.querySelectorAll("br");
  elements.forEach((el) => {
    el.setAttribute("aria-hidden", "true");
  });

  // 画面の表示部分の高さ取得
  const setFillHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  let vw = window.innerWidth;
  window.addEventListener("resize", () => {
    if (vw === window.innerWidth) {
      return;
    }
    vw = window.innerWidth;
    setFillHeight();
  });

  setFillHeight();
  // iPhoneのタグ移動の際にずれ防止
  setTimeout(() => {
    setFillHeight();
  }, 500);

  /**
   * ブラウザが webp をサポートしているかどうか
   * @returns webp をサポートしているなら true そうでないなら false
   */
  const supportsWebp = async () => {
    if (!self.createImageBitmap) return false;
    // webp の仮データ
    const webpData = "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=";
    const blob = await fetch(webpData).then((r) => r.blob());
    return createImageBitmap(blob).then(
      () => true,
      () => false
    );
  };
  // Webp対応か確認
  /**
   * webp 対応していれば target に webp、対応していなければ no-webp クラスを追加する
   */
  const addWebpDetectionClass = () => {
    if (supportsWebp()) {
      document.body.classList.add("webp");
    } else {
      document.body.classList.add("no-webp");
    }
  };

  addWebpDetectionClass();

  !(function () {
    const viewport = document.querySelector('meta[name="viewport"]');
    function switchViewport() {
      let value = "width=device-width,initial-scale=1";

      if (window.outerWidth < 375) {
        value = "width=375";
      } else if (1440 >= window.outerWidth && window.outerWidth > 576) {
        value = "width=1440";
      }

      if (viewport.getAttribute("content") !== value) {
        viewport.setAttribute("content", value);
      }
    }
    addEventListener("resize", switchViewport, false);
    switchViewport();
  })();
}

export default foundation;
