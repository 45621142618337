<template>
  <header class="cHeader">
    <div class="cHeader__wrapper">
      <div class="cHeader__menu">
        <div class="cHeader__menuWrapper">
          <nav class="cHeader__nav">
            <ul class="cHeader__list">
              <li class="home" :class="{ 'is-active': $route.path === '/' }">
                <router-link to="/">
                  <span>home</span>
                </router-link>
              </li>

              <li class="menu" :class="{ 'is-active': $route.path === '/menu' }">
                <router-link to="/menu">
                  <span>menu</span>
                </router-link>
              </li>

              <li class="salon" :class="{ 'is-active': $route.path === '/salon' }">
                <router-link to="/salon">
                  <span>salon</span>
                </router-link>
              </li>
            </ul>
          </nav>

          <div class="cHeader__top">
            <button type="button" @click="returnTop">
              <span class="cHeader__topIcon"></span>
            </button>
          </div>
        </div>
      </div>

      <div class="cHeader__copyright">Copyright &copy; polepole All Rights Reserved.</div>

      <div class="cHeader__info">
        <ul class="cHeader__sns">
          <li>
            <a href="https://www.instagram.com/_polepole_/" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 24 24">
                <path
                  d="M12 2.162c3.2 0 3.584.012 4.849.07a6.639 6.639 0 0 1 2.228.413 3.717 3.717 0 0 1 1.38.9 3.717 3.717 0 0 1 .9 1.38 6.639 6.639 0 0 1 .413 2.228c.058 1.265.07 1.645.07 4.849s-.012 3.584-.07 4.849a6.639 6.639 0 0 1-.413 2.228 3.974 3.974 0 0 1-2.277 2.277 6.639 6.639 0 0 1-2.228.413c-1.265.058-1.645.07-4.849.07s-3.584-.012-4.849-.07a6.639 6.639 0 0 1-2.228-.413 3.717 3.717 0 0 1-1.38-.9 3.718 3.718 0 0 1-.9-1.38 6.639 6.639 0 0 1-.413-2.228c-.058-1.265-.07-1.645-.07-4.849s.012-3.584.07-4.849a6.639 6.639 0 0 1 .413-2.228 3.717 3.717 0 0 1 .9-1.38 3.717 3.717 0 0 1 1.38-.9 6.639 6.639 0 0 1 2.228-.413C8.416 2.174 8.8 2.162 12 2.162M12 0C8.741 0 8.332.014 7.052.072A8.807 8.807 0 0 0 4.14.63a5.882 5.882 0 0 0-2.126 1.384A5.882 5.882 0 0 0 .63 4.14a8.807 8.807 0 0 0-.558 2.912C.014 8.332 0 8.741 0 12s.014 3.668.072 4.948A8.807 8.807 0 0 0 .63 19.86a5.882 5.882 0 0 0 1.384 2.125A5.883 5.883 0 0 0 4.14 23.37a8.808 8.808 0 0 0 2.913.558C8.332 23.986 8.741 24 12 24s3.668-.014 4.948-.072a8.808 8.808 0 0 0 2.913-.558 6.136 6.136 0 0 0 3.51-3.51 8.808 8.808 0 0 0 .558-2.913C23.986 15.668 24 15.259 24 12s-.014-3.668-.072-4.948a8.808 8.808 0 0 0-.558-2.912 5.883 5.883 0 0 0-1.384-2.125A5.882 5.882 0 0 0 19.86.63a8.807 8.807 0 0 0-2.912-.558C15.668.014 15.259 0 12 0Z"
                  fill="#000"
                />
                <path
                  d="M12 5.838A6.162 6.162 0 1 0 18.162 12 6.162 6.162 0 0 0 12 5.838ZM12 16a4 4 0 1 1 4-4 4 4 0 0 1-4 4Z"
                  fill="#000"
                />
                <circle cx="1.44" cy="1.44" r="1.44" transform="translate(16.966 4.154)" fill="#000" />
              </svg>
            </a>
          </li>

          <li>
            <a href="https://www.facebook.com/polepole.ay/" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 25 25">
                <path
                  d="M20.501 24.5h-16a4.005 4.005 0 0 1-4-4v-16a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4ZM8.742 10.585v2.559h2.239V19.7h2.638v-6.557h2.239l.32-2.559h-2.56v-1.6c0-.6.145-1.279 1.28-1.279h1.359V5.387c-.12 0-.307-.011-.543-.025-.383-.023-.907-.055-1.456-.055-.069 0-.139-.007-.208-.007a3.089 3.089 0 0 0-3.069 3.365v1.92Z"
                  fill="#000"
                  stroke="rgba(0,0,0,0)"
                  stroke-miterlimit="10"
                />
              </svg>
            </a>
          </li>

          <li>
            <a href="https://line.me/R/ti/p/%40pqt8866z" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 24 24">
                <defs>
                  <clipPath id="a"><path fill="#000" d="M0 0h24v24H0z" /></clipPath>
                </defs>
                <g clip-path="url(#a)" fill="#000">
                  <path
                    d="M19.2 0H4.8A4.8 4.8 0 0 0 0 4.8v14.4A4.8 4.8 0 0 0 4.8 24h14.4a4.8 4.8 0 0 0 4.8-4.8V4.8A4.8 4.8 0 0 0 19.2 0m-.368 15.3a28.245 28.245 0 0 1-6.865 5.074c-.932.394-.8-.25-.757-.47.018-.131.12-.748.12-.748a1.809 1.809 0 0 0-.028-.79c-.1-.244-.486-.37-.77-.431-4.2-.554-7.308-3.491-7.308-7 0-3.91 3.92-7.091 8.738-7.091s8.738 3.19 8.738 7.1a6.308 6.308 0 0 1-1.868 4.356"
                  />
                  <path
                    d="M10.187 9.055h-.614a.171.171 0 0 0-.17.17v3.806a.171.171 0 0 0 .17.17h.614a.17.17 0 0 0 .17-.17V9.225a.17.17 0 0 0-.17-.17m4.204 0h-.6a.171.171 0 0 0-.17.17v2.259l-1.745-2.357-.013-.018-.011-.011-.008-.007-.008-.006h-.711a.171.171 0 0 0-.17.17v3.775a.171.171 0 0 0 .17.17h.61a.171.171 0 0 0 .17-.17v-2.259l1.747 2.359a.16.16 0 0 0 .043.042l.011.006h.029a.131.131 0 0 0 .043 0h.613a.171.171 0 0 0 .17-.17V9.225a.171.171 0 0 0-.17-.17m-5.685 3.191H7.043V9.225a.171.171 0 0 0-.17-.17h-.614a.171.171 0 0 0-.17.17v3.806a.168.168 0 0 0 .048.12.173.173 0 0 0 .12.048h2.449a.171.171 0 0 0 .17-.17v-.613a.171.171 0 0 0-.17-.17m9.085 0h-1.668v-.637h1.666a.171.171 0 0 0 .17-.17v-.619a.171.171 0 0 0-.17-.17h-1.666v-.641h1.666a.171.171 0 0 0 .17-.17v-.615a.171.171 0 0 0-.17-.17h-2.448a.173.173 0 0 0-.12.048.167.167 0 0 0-.047.12v3.81a.168.168 0 0 0 .048.12.171.171 0 0 0 .12.048h2.449a.171.171 0 0 0 .17-.17v-.613a.171.171 0 0 0-.17-.17"
                  />
                </g>
              </svg>
            </a>
          </li>
        </ul>

        <div class="cHeader__infoText">
          <p>Open 10:00-17:00</p>

          <p>Closed day sun. and mon.</p>

          <p>phone 080-2429-0957</p>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  methods: {
    returnTop() {
      const bodyHeight = document.body.clientHeight;

      const duration = bodyHeight / 3;
      let startTime;
      let startScrollY;

      const easeOutCubic = (x) => {
        return 1 - Math.pow(1 - x, 3);
      };

      const scrollAnimation = () => {
        const progress = Math.min(1, (Date.now() - startTime) / duration);
        const scrollY = startScrollY * (1 - easeOutCubic(progress));
        window.scrollTo(0, scrollY);
        if (progress < 1) {
          requestAnimationFrame(scrollAnimation);
        }
      };

      startTime = Date.now();
      startScrollY = window.scrollY;
      scrollAnimation();
    },
  },
};
</script>

<style lang="scss" scoped>
.cHeader {
  position: relative;
  z-index: 1000;

  &__wrapper {
    position: relative;
  }

  &__menu {
    position: fixed;
    bottom: 30px;
    left: 25px;
    z-index: 1000;
    width: calc(100% - 50px);
    height: 50px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgb(0, 0, 0, 0.08);
      border-radius: 50vh;
      filter: blur(10px);
      transform: translate(1px, 1px);
      mix-blend-mode: multiply;
    }
  }

  &__menuWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: #fff;
    border-radius: 50vh;
  }

  &__copyright {
    position: absolute;
    top: calc(50vh - 108px);
    left: 15px;
    z-index: 1;
    height: max-content;
    min-height: 220px;
    font-family: var(--lato);
    font-size: 10px;
    letter-spacing: 0.08em;
    line-height: 1.4;
    writing-mode: vertical-rl;
  }

  &__info {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px 15px 0;
  }

  &__sns {
    display: flex;

    & li {
      width: 20px;
      height: 20px;

      & + li {
        margin-left: 12px;
      }
    }
  }

  &__infoText {
    font-family: var(--lato);
    font-size: 10px;
    letter-spacing: 0.08em;
    line-height: 1.7;
  }

  &__nav {
    width: 100%;
  }

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    & li {
      position: relative;
      font-family: var(--lato);
      font-size: 16px;
      letter-spacing: 0.08em;
      padding-bottom: 10px;

      & + li {
        margin-left: 35px;
      }

      &.is-active {
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: calc(50% - 2.5px);
          width: 5px;
          height: 5px;
          background-color: #000;
          border-radius: 50%;
        }
      }
    }
  }

  &__top {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    margin-left: auto;
    border-left: 1px solid #dedede;

    & button {
      width: 100%;
      height: 100%;
    }
  }

  &__topIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &::after {
      content: "";
      width: 7px;
      height: 7px;
      border-top: 1px solid #000;
      border-right: 1px solid #000;
      transform: rotate(-45deg);
    }
  }
}

@include g.responsive(sm) {
  .cHeader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1000;
    pointer-events: none;

    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      pointer-events: none;
    }

    &__menu {
      position: relative;
      top: 0;
      left: 0;
      z-index: 0;
      display: flex;
      width: auto;
      padding: 32px 40px 0;
      pointer-events: auto;

      &::after {
        content: none;
      }
    }

    &__menuWrapper {
      width: auto;
      background: none;
    }

    &__list {
      justify-content: flex-start;

      & li {
        & + li {
          margin-left: 0;
        }

        &.salon {
          margin-left: 25px;
        }

        &.home {
          position: relative;
          display: flex;
          align-items: center;
          order: 1;
          opacity: 0;
          pointer-events: none;
          margin-left: 35px;
          padding-left: 15px;
          transition: opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1);

          &::after {
            content: none;
          }

          &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 7px;
            height: 7px;
            border-bottom: 1px solid #000;
            border-left: 1px solid #000;
            transform: rotate(45deg);
          }

          &:not(.is-active) {
            opacity: 1;
            pointer-events: auto;
          }
        }

        &.is-active:not(.home) {
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: calc(50% - 2.5px);
            width: 5px;
            height: 5px;
            background-color: #000;
            border-radius: 50%;
          }
        }
      }
    }

    &__top {
      display: none;
    }

    &__info {
      flex-direction: column;
      padding: 40px;
      pointer-events: auto;
    }

    &__infoText {
      margin-top: 2rem;
    }
  }

  .js {
    & .cHeader {
      mix-blend-mode: difference;

      &__list {
        & li {
          & a {
            color: #fff;
          }

          &.home {
            &::before {
              border-bottom: 1px solid #fff;
              border-left: 1px solid #fff;
            }
          }

          &.is-active:not(.home) {
            &::after {
              background-color: #fff;
            }
          }
        }
      }

      &__copyright,
      &__infoText {
        color: #fff;
      }

      &__sns {
        & li svg > * {
          fill: #fff;
        }
      }
    }
  }
}
</style>
